<template>
  <div class="col-2 bg" id="allUserDashCont">
    <div
      class="pl-0 user_dash py-5"
      :style="`min-height:${minHeight}px;`"
    >
      <barra-prog-vue></barra-prog-vue>
      <div class="my-3 justify-content-center">
        <div class="image_div" id="ImageDivId">
          <img
            :src="getImg(user.userLogin.photo)"
            class="img_user"
            @error="errorImage"
          />
        </div>
      </div>

      <div>
        <p>
          Olá
          <strong>{{ quebraNome(user.userLogin.displayName) }}!!</strong>
        </p>

        <p v-if="user.userLogin.profile == 3" style="
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
					color: #fff;
          margin: 20px 0
          "
				>
					Faltam <strong> {{ aroundMath(user.tenantDifferenceScore) }}%</strong>
					para você alcançar a sua meta do mês. <br>
          <!-- isso equivale a {{ valueConvert(valorSomando) teste }} -->
				</p>
        <div v-if="user.userLogin.profile === 2">

        <p v-if="aroundMath(user.userScore) < 100" >
          Faltam {{ aroundMathFixed(user.differenceScoreTotal) }}% para você alcançar a sua
          meta do mês.
        </p>
   
        <p v-else>
          <strong>Parabens</strong> você concluiu sua meta para esse mês!
        </p>
        </div>

        <p v-if="user.userLogin.profile === 2" class="textUser" >
            Para a sua unidade atingir a meta do mês, você e sua equipe precisam faturar <strong>{{valueConvert(user.differenceGoalOverAll)}}.</strong>
        </p>

        <p id="messageGoal" class="textUser" hidden="true">
          Atenção: O valor distribuído é inferior ao valor da meta da unidade.
        </p>

        <p> <strong>Contamos com você. 😉</strong></p>

        <p class="mb-2 textPd">Lembre-se de manter seus dados atualizados</p>
        <router-link :to="'/perfil'" class="mb-0 routerData">
          <strong class="mt-3">clicando aqui.</strong>
        </router-link>

        
      </div>
    </div>
  </div>
</template>

<script>

import barraProgVue from './barraProg.vue';
import HTTP from "@/api_system";

export default {
  components: {
    barraProgVue
  },
  data() {
    return {
      minHeight: window.innerHeight - 140,
      valorTotalVendas: 0,
      valorTotalUnidades: 0,
      valorTotalDaMetaDaUnidadeNaoDistribuido: false,
      fill: { gradient: ["red", "green", "blue"] },
      showMessageGoal: false
    };
  },
  mounted() {
    this.valorTotalVendas = this.valorTotal(this.user.tenantOverallSold, this.user.tenantScore)
    this.valorTotalUnidades = this.valorTotal(this.user.userOverallSold, this.user.userScore)
    this.getUsers()
  },
  beforeUpdate() {
    this.valorTotalVendas = this.valorTotal(this.user.tenantOverallSold, this.user.tenantScore)
    this.valorTotalUnidades = this.valorTotal(this.user.userOverallSold, this.user.userScore)
  },
  methods: {
    quebraNome(val) {
      var str = val.split(" ");
      return str[0];
    },

    getTotal(val) {
      return 100 - val;
    },
    valorTotal(valor, porcento) {
        return (100 * valor) / this.around(porcento)
    },
    progress(event,progress,stepValue){
        console.log(stepValue);
    },
    progress_end(event){
      console.log("Circle progress end");
    },
    async getUsers() {
      await HTTP.get(
        `services/app/User/GetSumUsersGoalByTenant?tenantId=${this.$store.getters.get_tenant.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      ).then(res => {
        if (res.data.result) {
          $('#messageGoal')[0].hidden = false
        } else {
          $('#messageGoal')[0].hidden = true
        }
      });
    }
  },
  computed: {
    user() {
      return this.$store.state.login;
    },
    userFranquiado() {
      return this.$store.state.login.userLogin.profile
    },
    valorSomando(){
      return this.valorTotalVendas - this.user.tenantOverallSold
    },
    valorSomandoUnidade(){
      return this.valorTotalUnidades - this.user.userOverallSold
    }
  },
};
</script>

<style scoped>
.bg {
  background-color: rgb(27, 96, 168);
}
p {
  color: #ffffff;
  font-size: 14px;
}
.ml-30 {
  margin-left: 30%;
}
.user_dash {
  flex-direction: column;
  /* min-height: 100vh; */
}

.image_div {
  border-radius: 50%;
  border: none;
  padding: 1px;
  padding-left: 3px;
  padding-top: 2px;
  height: 100px;
  width: 100px;
  overflow: hidden;
}
.image_div .img_user {
  border-radius: 50%;
  object-fit: cover;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
a {
  color: #ffffff;
}

.textUser {
  line-height: 1.4;
}

.textPd {
  padding-top: 30px;
  border-top: 2px solid #3F88C1;
  margin: 115px 0 0;
  line-height: 1.5;
  color: #98C4E6;
}

.routerData strong{
  font-weight: 700;
  font-size: 16px;
}

.hiddenMessageGoal {
  visibility: collapse;
}
@media screen and (max-width:767px){
  #allUserDashCont{
    display: none;
  }
}
</style>


