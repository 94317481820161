<template>
<div class="barraProg" id="Prog">
    <div class="box">
        <svg>
            <circle cx="52" cy="52" r="48"></circle>
            <circle id="circleProgress" cx="52" cy="52" r="48" ></circle>
        </svg>
    </div>
   
</div>
</template>

<script>



export default {
    methods: {
        barraFunc() {
            var circle = document.querySelector('#circleProgress');
            circle.style.strokeDashoffset = this.$store.state.login.userScore * 302 / 100;
            if(this.$store.state.login.userScore >= 302){
                circle.style.strokeDashoffset == 302
            }
        },
        
        ApenasVendedor(){
            if(this.user.profile !== 3) {
                document.getElementById("Prog").style.display="none"
            }if(this.user.profile !== 3) { 
                document.getElementById("ImageDivId").style.border="4px #3F88C1 solid"
                document.getElementById("ImageDivId").style.padding="1px"
                document.getElementById("ImageDivId").style.paddingLeft="2px" 
            }
        },
        att(){
            setTimeout(this.barraFunc,500);
        }
    },

    computed: {
        totalMetas() {
            return this.$store.state.login.userScore;
        },
        user() {
            return this.$store.state.login.userLogin;
        }
    },
    mounted() {
        this.att();
        this.ApenasVendedor();
	},
}
</script>

<style scoped>
    .box svg{
        padding-top: 15px;
        position: absolute;
        width: 120px;
        height: 130px;
        padding-right: 5px;
    }
    circle{
        padding-top: 15px;
        width: 110px;
        height: 110px;
        fill: none;
        stroke: orange;
        stroke-width: 4;
        transform: translate(5px.5px);
        stroke-dasharray: 302;
        stroke-dashoffset: 302;
    }
    circle:nth-child(1){
        stroke-dashoffset: 0;
        stroke: darkorange;
    }
    circle:nth-child(2){
        stroke: #0084ff
    }
</style>