<template>
  <div>
    <transition name="fade" mode="out-in">
      <modal id="modal-mural" v-if="showModal && this.sizeWindow > 767" @close="showModal = false"></modal>
      <modalMuralMobile id="modal-mural-mobile" v-if="showModal && this.sizeWindow <= 767" @close="showModal = false"></modalMuralMobile>
    </transition>
  </div>
</template>

<script>
import modal from "./modal";
import modalMuralMobile from "./modalMuralMobile";
import HTTP from "@/api_system";
import Slide from "./splide";
export default {
  components: { modal, Slide, modalMuralMobile },
  data() {
    return {
      showModal: false,
      sizeWindow: 0
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },

  mounted() {
    this.sizeWindow = $(window).width();
    this.getList();
  },
  methods: {
    getList() {
      let user = this.$store.getters.getUser;
      HTTP.get(`services/app/Notice/GetDashBoardNotices`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` },
      })
        .then((response) => {
          let res = response.data.result;
          let messageProfile;
          for (let i = 0; i < res.length; i++) {
            if (user.profile == res[i].profile || res[i].profile == 0) {
              messageProfile = true;
              break;
            }
            
          }
          if (messageProfile) {
            let modal = sessionStorage.getItem("modal");
            if (!modal) {
              if (res.length) {
                this.showModal = true;
              }
              sessionStorage.setItem("modal", true);
            }
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
