<template>
  <div class="container-fluid">
    <div
      class="dashboard"
      :class="this.user.profile != 1 ? ' col-md-12 px-0' : 'container px-0'"
    >
      <div :class="this.user.profile != 1 ? 'row' : 'col-md-12 p-0'">
        <user_dash v-if="this.user.profile != 1" />
        <div
          class="dash px-3"
          :style="`min-height:${minHeight}px;`"
          :class="this.user.profile != 1 ? 'col-md-9' : 'col-md-12 p-0'"
        >
          <component :is="cpDash" />
          <div v-if="user.profile === 3 || user.profile === 2">
            <Modal />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import user_dash from "@/components/utils/_User_dash";
import Modal from "@/components/modal/modal_vendendor.vue";
import HTTP from "@/api_system";
import { mapGetters, mapActions } from "vuex";
function lazyLoad(cp) {
  return () => import(`@/components/dashboard_${cp}/_Dashboard`);
}
export default {
  components: {
    user_dash,
    Modal
  },
  data() {
    return {
      cpDash: "",
      minHeight: window.innerHeight - 140
    };
  },
  mounted() {
    var cp;
    if (this.user.profile == 1) {
      cp = "master";
    } else if (this.user.profile == 2) {
      cp = "Franqueado";
    } else {
      cp = "vendedor";
    }
    this.cpDash = lazyLoad(cp);
    this.$store.dispatch("getServices");
    this.OpenModal();
  },
  methods: {
    ...mapActions(["getServices"]),
    OpenModal() {
      $("#app").removeClass("overlay_custom");
      $("#modal_editCliente")
        .appendTo("body")
        .modal("show");
    },
    closeModal() {
      $("#modal_editCliente").modal("hide");
    },
    getList() {
      HTTP.get(`services/app/Notice/GetDashBoardNotices`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(response => {
          this.page = response.data.result;
        })
        .catch(err => console.log(err));
    }
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    },
    get_message: function() {
      if (this.get_message == 3) {
        this.getServices();
      }
    }
  }
};
</script>

<style scoped>
.dash {
  background-color: #2474b2;
}
</style>
